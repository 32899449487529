import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import { Button as BaseButton } from 'reakit/Button';
import styles from '@moonshineragency/ui/src/components/Button/Button.module.scss';

/**
 * Base Button ontop of reakit - styling only
 */

const Button = React.forwardRef(
  (
    {
      theme,
      children,
      shape,
      className,
      disabled,
      size,
      isFullWidth,
      ...other
    },
    ref,
  ) => (
    <BaseButton
      className={classNames(
        styles[theme],
        styles[shape],
        styles.container,
        styles[`${size}Size`],
        isFullWidth && styles.fullWidth,
        className,
      )}
      disabled={disabled}
      ref={ref}
      {...other}
    >
      {children}
    </BaseButton>
  ),
);

const IconButton = React.forwardRef(({ children, disabled, ...other }, ref) => (
  <BaseButton
    className={styles.container}
    disabled={disabled}
    ref={ref}
    {...other}
  >
    {children}
  </BaseButton>
));

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  shape: PropTypes.oneOf(['bubble', 'default', 'pill']),
  theme: PropTypes.oneOf([
    'primary',
    'secondary',
    'contrast',
    'link',
    'narrow',
  ]),
  size: PropTypes.oneOf(['default', 'small']),
  isFullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};
Button.defaultProps = {
  theme: 'primary',
  shape: 'default',
  className: null,
  size: 'default',
  isFullWidth: false,
  disabled: false,
};

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
IconButton.defaultProps = {
  className: null,
  disabled: false,
};

export { Button as default, IconButton };
