import { ReactComponent as WalkingIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/walking.svg';
import { ReactComponent as HandExandIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/handExpand.svg';
import { ReactComponent as BladderIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/bladder.svg';
import { ReactComponent as BoneIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/bone.svg';
import { ReactComponent as BowelIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/bowel.svg';
import { ReactComponent as GeneralHealthIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/generalHealth.svg';
import { ReactComponent as MentalHealthIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/mentalHealth.svg';
import { ReactComponent as LoveHeartIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/loveHeart.svg';
import { ReactComponent as SkinIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/skin.svg';
import { ReactComponent as xSmallCheck } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/xSmallCheck.svg';
import { ReactComponent as WaveIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/wave.svg';
import { ReactComponent as PainReliefIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/painRelief.svg';
import { ReactComponent as SensoryBenefitsIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/sensoryBenefits.svg';
import { ReactComponent as PillIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/pill.svg';
import { ReactComponent as ScalpelIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/scalpel.svg';
import { ReactComponent as MedicalRehabilitationIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/medicalRehabilitation.svg';
import { ReactComponent as ScienceCellsIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/scienceCells.svg';
import { ReactComponent as TechnologyIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/technology.svg';
import { ReactComponent as AlternativeOrComplimentaryIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/alternativeOrComplimentary.svg';
import { ReactComponent as SmallCheckBadgeIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallCheckBadge.svg';
import { ReactComponent as ArrowLongRightIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/arrowLongRight.svg';
import { ReactComponent as SmallCheckIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallCheck.svg';
import { ReactComponent as SmallClockSoonIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallClockSoon.svg';
import { ReactComponent as SmallArrowDown } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallArrowDown.svg';
import { ReactComponent as SmallArrowUp } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallArrowUp.svg';

import { ReactComponent as SmallArrowLongLeftIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallArrowLongLeft.svg';
import { ReactComponent as SmallArrowLongRightIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallArrowLongRight.svg';
import {
  ReactComponent as LoginIcon,
  ReactComponent as UsersCircleIcon,
} from '@moonshineragency/ui/src/assets/tokens/icons/icons/userCircle.svg';
import { ReactComponent as LargeEmaiLFastIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-large/largeMailFast.svg';
import { ReactComponent as PrinterIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallPrinter.svg';
import { ReactComponent as SmallHyperlinkIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallHyperlink.svg';
import { ReactComponent as NavigationIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/navigationMenu.svg';
import { ReactComponent as CrossXIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/crossX.svg';
import { ReactComponent as SmallCrossXIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallCrossX.svg';
import { ReactComponent as ArrowForwardIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/arrowForward.svg';
import { ReactComponent as AlarmBellIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/alarmBell.svg';
import { ReactComponent as MailFastIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/mailFast.svg';
import { ReactComponent as CheckBadgeIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/checkBadge.svg';
import { ReactComponent as LargeBellIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-large/largeAlarmBell.svg';
import { ReactComponent as LargeCheckIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-large/largeCheckBig.svg';
import { ReactComponent as GenderAllIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/genderAll.svg';
import { ReactComponent as GenderFemaleIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/genderFemale.svg';
import { ReactComponent as GenderMaleIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/genderMale.svg';
import { ReactComponent as ScopeIcon } from '@moonshineragency/ui/src/assets/tokens/icons/images/scope.svg';
import { ReactComponent as AsiaIcon } from '@moonshineragency/ui/src/assets/tokens/icons/images/asia.svg';
import { ReactComponent as WingsIcon } from '@moonshineragency/ui/src/assets/tokens/icons/images/wings-for-life.svg';
// import { ReactComponent as LogoWingsIcon } from '@moonshineragency/ui/src/assets/tokens/icons/images/wings-logo.svg';
import { ReactComponent as LogoSymbolIcon } from '@moonshineragency/ui/src/assets/tokens/icons/logo/logoSymbol.svg';
import { ReactComponent as CalendarIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/calendar.svg';
import { ReactComponent as HospitalIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/hospitalHouse.svg';
import { ReactComponent as ClockSoonIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/clockSoon.svg';
import { ReactComponent as PinIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/pin.svg';
import { ReactComponent as TrialsIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/trials.svg';
import { ReactComponent as LargeWriteLetterIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-large/largeWriteLetter.svg';
import { ReactComponent as SmallWarningCircle } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallWarningCircle.svg';
import { ReactComponent as Dashboard } from '@moonshineragency/ui/src/assets/tokens/icons/icons/dashboard.svg';
import { ReactComponent as SmallCheckFilled } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallCheckFilled.svg';
import { ReactComponent as SmallCrossXFilled } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallCrossXFilled.svg';
import { ReactComponent as SearchIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons/search.svg';
import { ReactComponent as SearchIconSmall } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallSearch.svg';
import { ReactComponent as SmallSortAsc } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/SmallSortAsc.svg';
import { ReactComponent as SmallSortDesc } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/SmallSortDes.svg';
import { ReactComponent as SmallSortNone } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/SmallSortNone.svg';
import { ReactComponent as LargeConversationText } from '@moonshineragency/ui/src/assets/tokens/icons/icons-large/largeConversationText.svg';
import { ReactComponent as LargeLaboratorySample } from '@moonshineragency/ui/src/assets/tokens/icons/icons-large/largeLaboratorySample.svg';
import { ReactComponent as LogoWingsColored } from '@moonshineragency/ui/src/assets/tokens/icons/logo/logoWingsColored.svg';
import { ReactComponent as LogoWingsWhite } from '@moonshineragency/ui/src/assets/tokens/icons/logo/logoWingsWhite.svg';
import { ReactComponent as LogoAsiaColored } from '@moonshineragency/ui/src/assets/tokens/icons/logo/logoAsiaColored.svg';
import { ReactComponent as LogoScopeColored } from '@moonshineragency/ui/src/assets/tokens/icons/logo/logoScopeColored.svg';
import { ReactComponent as View } from '@moonshineragency/ui/src/assets/tokens/icons/icons/view.svg';
import { ReactComponent as SmallDownload } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallDownload.svg';
import { ReactComponent as warningCircle } from '@moonshineragency/ui/src/assets/tokens/icons/icons/warningCircle.svg';
import { ReactComponent as checkBig } from '@moonshineragency/ui/src/assets/tokens/icons/icons/checkBig.svg';
import { ReactComponent as SmallFilter } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallFilter.svg';

export const getIconComponent = name => {
  switch (name) {
    // benefit icons:
    case 'bladder':
      return BladderIcon;
    case 'handexpand':
      return HandExandIcon;
    case 'bone':
      return BoneIcon;
    case 'bowel':
      return BowelIcon;
    case 'generalhealth':
      return GeneralHealthIcon;
    case 'mentalhealth':
      return MentalHealthIcon;
    case 'sensorybenefits':
      return SensoryBenefitsIcon;
    case 'skin':
      return SkinIcon;
    case 'wave':
      return WaveIcon;
    case 'walking':
      return WalkingIcon;

    // intervention type icons:
    case 'sciencecells':
      return ScienceCellsIcon;
    case 'pill':
    case 'drug':
      return PillIcon;
    case 'medicalrehabilitation':
      return MedicalRehabilitationIcon;
    case 'scalpel':
      return ScalpelIcon;
    case 'technology':
      return TechnologyIcon;
    case 'alternativeorcomplimentary':
      return AlternativeOrComplimentaryIcon;

    case 'checkBadge':
      return CheckBadgeIcon;
    case 'alarmBell':
      return AlarmBellIcon;
    case 'mailFast':
      return MailFastIcon;
    case 'all':
      return GenderAllIcon;
    case 'female':
      return GenderFemaleIcon;
    case 'male':
      return GenderMaleIcon;
    case 'painrelief':
      return PainReliefIcon;
    case 'recruiting-soon':
      return SmallClockSoonIcon;
    case 'active':
    case 'recruiting':
    case 'by-invitation':
      return SmallCheckFilled;
    case 'ended':
      return SmallCrossXFilled;

    default: {
      return LoveHeartIcon;
    }
  }
};

const IconComponents = {
  AlternativeOrComplimentaryIcon,
  SmallCheckBadgeIcon,
  HospitalIcon,
  SmallCheckIcon,
  TrialsIcon,
  UsersCircleIcon,
  PinIcon,
  ClockSoonIcon,
  BladderIcon,
  CheckBadgeIcon,
  SmallArrowDown,
  CalendarIcon,
  SmallClockSoonIcon,
  WingsIcon,
  LargeBellIcon,
  ScopeIcon,
  xSmallCheck,
  AsiaIcon,
  ArrowLongRightIcon,
  // LogoWingsIcon,
  BoneIcon,
  LogoSymbolIcon,
  BowelIcon,
  MailFastIcon,
  LargeEmaiLFastIcon,
  LargeCheckIcon,
  GeneralHealthIcon,
  HandExandIcon,
  LoveHeartIcon,
  MedicalRehabilitationIcon,
  MentalHealthIcon,
  ArrowForwardIcon,
  PrinterIcon,
  AlarmBellIcon,
  PainReliefIcon,
  SmallHyperlinkIcon,
  NavigationIcon,
  CrossXIcon,
  SmallCrossXIcon,
  PillIcon,
  ScalpelIcon,
  ScienceCellsIcon,
  LoginIcon,
  SensoryBenefitsIcon,
  SkinIcon,
  TechnologyIcon,
  WalkingIcon,
  WaveIcon,
  SmallArrowLongLeftIcon,
  SmallArrowLongRightIcon,
  LargeWriteLetterIcon,
  SmallWarningCircle,
  Dashboard,
  SmallCheckFilled,
  SmallCrossXFilled,
  SearchIcon,
  SearchIconSmall,
  SmallSortAsc,
  SmallSortDesc,
  SmallSortNone,
  LargeConversationText,
  LargeLaboratorySample,
  SmallArrowUp,
  LogoWingsColored,
  LogoWingsWhite,
  LogoAsiaColored,
  LogoScopeColored,
  View,
  SmallDownload,
  warningCircle,
  checkBig,
  SmallFilter,
};

export default IconComponents;
