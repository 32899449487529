// eslint-disable-next-line no-restricted-imports
import store from '../store';
import axios from 'axios';

const axiosInstance = axios.create({
  timeout: 240000,
});
axiosInstance.interceptors.request.use(config => {
  const state = store.getState();
  if (state.user) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${state.user.token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
  }
  return config;
});

const Client = {
  admin: {
    login: (email, password) =>
      axiosInstance.post('/api/admin/user/login', { email, password }),
    trials: {
      save: (id, data) =>
        axiosInstance.patch(`/api/admin/trial/${id}`, { ...data }),
      publish: id => axiosInstance.patch(`/api/admin/trial/${id}/publish`),
      get: id => axiosInstance.get(`/api/admin/trial/${id}`),
      versions: (id, versionOne, versionTwo) =>
        axiosInstance.get(
          `/api/admin/trial/${id}/compare/${versionOne}/${versionTwo}`,
        ),
      curation: {
        update: (id, data) =>
          axiosInstance.patch(`/api/admin/trial/${id}/curation`, {
            ...data,
          }),
        approve: id => axiosInstance.patch(`/api/admin/trial/${id}/approve`),
      },
      curators: {
        update: (id, data) =>
          axiosInstance.patch(`/api/admin/trial/${id}/curators`, {
            ...data,
          }),
      },
    },
    usersList: {
      get: () => axiosInstance.get(`/api/admin/user/`),
    },
  },
  search: {
    location: q => axiosInstance.get(`/api/srch`, { params: { q } }),
  },
  userTrial: {
    get: id =>
      axiosInstance.get(`/api/trial/${id}`, {
        headers: {
          'content-type': 'application/json',
          'access-control-allow-origin': '*',
        },
      }),
  },
  locationTrial: {
    post: (data, id) =>
      axiosInstance.post(`/api/trial/locations/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        ...data,
      }),
  },
  trialList: {
    get: page =>
      axiosInstance.get(`/api/trial`, {
        params: page,
      }),
  },

  trialLocations: {
    get: (id, latitude, longitude) =>
      axiosInstance.get(`/api/trial/${id}/locations`, {
        params: { lat: latitude, lng: longitude },
      }),
  },
  trialContact: {
    post: (id, data) =>
      axiosInstance.post(`/api/trial/contact/${id}`, { ...data }),
  },
  trialAdmin: {
    get: id => axiosInstance.get(`/api/admin/trial/${id}`),
  },
  trialListAdmin: {
    get: (
      page,
      sortColumn,
      sortDirection,
      id,
      title,
      organization,
      trialType,
      importedAtFrom,
      importedAtTo,
    ) =>
      axiosInstance.get(`/api/admin/trial`, {
        params: {
          page,
          sortColumn,
          sortDirection,
          id,
          title,
          organization,
          trialType,
          importedAtFrom,
          importedAtTo,
        },
      }),
  },
  usersList: {
    get: () => axiosInstance.get(`/api/admin/user/`),
  },
  updateUser: {
    patch: (id, data) =>
      axiosInstance.patch(`/api/admin/user/${id}`, { ...data }),
  },
  forgotPassword: {
    post: email => axiosInstance.post('/api/forgot-password', email),
  },
  createUser: {
    post: data => axiosInstance.post(`/api/admin/user/register`, data),
  },
  userRoles: {
    get: () => axiosInstance.get(`/api/user/roles/`),
  },
  resetPassword: {
    post: data => axiosInstance.post('/api/reset-password', data),
  },
  deleteUser: {
    delete: id => axiosInstance.delete(`/api/admin/user/${id}`),
  },

  trialHistory: {
    get: id => axiosInstance.get(`/api/admin/trial/${id}/history`),
  },
  writeComments: {
    post: (id, text) =>
      axiosInstance.post(`/api/admin/trial/${id}/comments`, text),
  },
  trialComments: {
    get: id => axiosInstance.get(`/api/admin/trial/${id}/comments`),
  },

  unsubscribeNotifications: {
    get: email =>
      axiosInstance.get('/api/notifications/email/unsubscribe', {
        params: { email },
      }),
  },
  notifications: {
    post: data =>
      axiosInstance.post('/api/notifications/email/create', { ...data }),
  },
  benefits: {
    get: () => axiosInstance.get(`/api/seeder/benefits`),
  },
  trials: {
    search: (page, data) =>
      axiosInstance.post(`/api/trial/search`, data, {
        params: { page },
      }),
  },
  csvTrialsData: {
    post: data =>
      axiosInstance.post('/api/trial/search', {
        filters_counter: data?.filters_counter,
        is_recruiting_now_soon: data?.is_recruiting_now_soon,
        include_uncurated: data?.include_uncurated,
        location_name: data?.location_name,
        location: {
          lat: data?.location?.value?.lat,
          lng: data?.location?.value?.lng,
        },
        severity: data?.severity,
        injuryLevel: data?.injuryLevel,
        time_since_injury: data?.time_since_injury,
        interventions: data?.interventions,
        potential_benefits: data?.potential_benefits,
        trial_type: data?.trial_type,
        unit: data?.unit,
        sort_by: 'updated',
        format: 'csv',
      }),
  },
  interventions: {
    get: () => axiosInstance.get(`/api/seeder/interventions`),
  },
  measures: {
    get: () => axiosInstance.get(`/api/seeder/measures`),
  },
  injuries: {
    get: () => axiosInstance.get(`/api/seeder/injuries`),
  },
  mechanisms: {
    get: () => axiosInstance.get(`/api/seeder/mechanisms`),
  },
  scientificTrialSearch: {
    get: (id, title, page) =>
      axiosInstance.get(`/api/scientific-trial`, {
        params: { id, title, page },
      }),
  },
};

export default Client;
