import React from 'react';
import PropTypes from 'prop-types';
import styles from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper.module.scss';
import classNames from 'classnames';

const IconWrapper = ({ className, Icon, ...iconProps }) => {
  return (
    <div className={classNames(styles.container, className || null)}>
      <Icon {...iconProps} />
    </div>
  );
};

IconWrapper.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.func.isRequired,
};

IconWrapper.defaultProps = {
  className: null,
};

export default IconWrapper;
