import styles from 'components/Container/Container.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Container = ({ as, children, size, className, ...props }) => {
  const Element = { as };
  return (
    <Element.as
      className={classNames(styles.container, styles[size], className)}
      {...props}
    >
      {children}
    </Element.as>
  );
};
Container.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full', 'wide']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  as: PropTypes.node,
};

Container.defaultProps = {
  size: 'xl',
  className: '',
  as: 'div',
};

export default Container;
