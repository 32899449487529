import styles from '@moonshineragency/ui/src/components/Heading/Heading.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Heading = React.forwardRef(
  (
    {
      as,
      children,
      size,
      noSpacing,
      notResponsive,
      className,
      align,
      theme = 'default',
      ...props
    },
    ref,
  ) => {
    const Element = {
      as: as || size,
    };
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <Element.as
        ref={ref}
        className={classNames(
          styles.heading,
          className,
          styles[size],
          styles[align],
          {
            [styles.noSpacing]: noSpacing,
            [styles.notResponsive]: notResponsive,
          },
          styles[theme],
        )}
        // eslint-disable-next-line react/no-children-prop
        children={children || null}
        {...props}
      />
    );
  },
);

Heading.propTypes = {
  size: PropTypes.oneOf(['h1big', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node,
  theme: PropTypes.oneOf([
    'default',
    'contrast',
    'primary',
    'secondary',
    'neutral',
  ]),
  align: PropTypes.string,
  notResponsive: PropTypes.bool,
  className: PropTypes.string,
};

Heading.defaultProps = {
  as: null,
  size: 'h1',
  align: null,
  notResponsive: false,
  className: '',
  children: '',
  theme: 'default',
};
export default Heading;
