import styles from './InputField.module.scss';
import PropTypes from 'prop-types';
import React from 'react';
import {
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';
import classNames from 'classnames';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';

const InputMessage = ({ name, ...other }) => (
  <FormMessage
    className={styles.message}
    {...other}
    name={name}
    aria-atomic="true"
  />
);
// InputField used together with the form from reakit
const InputField = React.forwardRef(
  (
    {
      placeholder,
      label,
      name,
      className,
      required,
      variant,
      children,
      noMessage,
      labelHidden,
      icon,
      ...form
    },
    ref,
  ) => {
    return (
      <div className={classNames(styles.container, className)}>
        <FormLabel
          className={
            labelHidden
              ? classNames(styles.label, styles.hidden, styles[variant])
              : classNames(styles.label, styles[variant], {
                  [styles.hasError]: form.errors[name] && form.touched[name],
                })
          }
          {...form}
          name={name}
        >
          {label}
        </FormLabel>
        {icon && <IconWrapper Icon={icon} className={styles.icon} />}
        {children || (
          <FormInput
            className={classNames(styles.input, styles[variant], {
              [styles.hasIcon]: !!icon,
            })}
            {...form}
            name={name}
            placeholder={placeholder}
            required={required}
            ref={ref}
          />
        )}
        {!noMessage && <InputMessage {...form} name={name} />}
      </div>
    );
  },
);

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.oneOf(['closed', '']),
  noMessage: PropTypes.bool,
  icon: PropTypes.func,
};
InputField.defaultProps = {
  labelHidden: false,
  placeholder: '',
  required: false,
  variant: '',
  noMessage: false,
  icon: '',
};

export { InputField as default, InputMessage };
