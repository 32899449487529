import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React from 'react';
import PropTypes from 'prop-types';
import { unstable_Form as ReaKitForm } from 'reakit/Form';
import styles from '@moonshineragency/ui/src/components/Form/Form.module.scss';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';

/**
 * Renders the error message for the input of given label name.
 * @param {String} labelName
 * @returns React.Element
 */
export const renderInputErrorFor = message => {
  return (
    <div>
      <IconWrapper
        Icon={IconComponents.SmallWarningCircle}
        className={styles.smallWarningCircle}
      />
      <span className={styles.formErrorMessage}>{message}</span>
    </div>
  );
};

const Form = ({ hasGeneralFormErrorMessage, children, submitArea, form }) => {
  const hasFormErrors =
    Object.keys(form.errors).length > 0 &&
    Object.keys(form.touched).length > 0 &&
    !form.valid;

  return (
    <ReaKitForm {...form}>
      {children}
      {hasGeneralFormErrorMessage && hasFormErrors && (
        <div className={styles.errorMessageWrapper}>
          <IconWrapper
            Icon={IconComponents.SmallWarningCircle}
            className={styles.smallWarningCircleWhite}
          />
          Please check your entry and try again.
        </div>
      )}
      {submitArea}
    </ReaKitForm>
  );
};
Form.defaultProps = {
  hasFormErrorsMessage: false,
};
Form.propTypes = {
  hasFormErrorsMessage: PropTypes.bool,
  submitArea: PropTypes.element.isRequired,
  form: PropTypes.shape({}).isRequired,
};

export default Form;
